pre {
  code[as='code'] {
    .hljs-comment {
      color: #999;
    }
    .hljs-tag {
      color: #b4b7b4;
    }
    .hljs-operator,
    .hljs-punctuation,
    .hljs-subst {
      color: #ccc;
    }
    .hljs-operator {
      opacity: 0.7;
    }
    .hljs-bullet,
    .hljs-deletion,
    .hljs-name,
    .hljs-selector-tag,
    .hljs-template-variable,
    .hljs-variable {
      color: #f2777a;
    }
    .hljs-attr,
    .hljs-link,
    .hljs-literal,
    .hljs-number,
    .hljs-symbol,
    .hljs-variable.constant_ {
      color: #f99157;
    }
    .hljs-class .hljs-title,
    .hljs-title,
    .hljs-title.class_ {
      color: #fc6;
    }
    .hljs-strong {
      font-weight: 700;
      color: #fc6;
    }
    .hljs-addition,
    .hljs-code,
    .hljs-string,
    .hljs-title.class_.inherited__ {
      color: #9c9;
    }
    .hljs-built_in,
    .hljs-doctag,
    .hljs-keyword.hljs-atrule,
    .hljs-quote,
    .hljs-regexp {
      color: #6cc;
    }
    .hljs-attribute,
    .hljs-function .hljs-title,
    .hljs-section,
    .hljs-title.function_,
    .ruby .hljs-property {
      color: #69c;
    }
    .diff .hljs-meta,
    .hljs-keyword,
    .hljs-template-tag,
    .hljs-type {
      color: #c9c;
    }
    .hljs-emphasis {
      color: #c9c;
      font-style: italic;
    }
    .hljs-meta,
    .hljs-meta .hljs-keyword,
    .hljs-meta .hljs-string {
      color: #a3685a;
    }
    .hljs-meta .hljs-keyword,
    .hljs-meta-keyword {
      font-weight: 700;
    }
  }
}
